export const formatTime = (date: Date | string): string => {
    // Si la fecha es un string, convertirla a Date
    const dateObj = typeof date === 'string' ? new Date(date) : date;
  
    // Obtener la hora en formato local ajustada
    // Esto crea un nuevo objeto Date basado en la fecha original (en UTC), pero lo ajusta al huso horario local automáticamente
    const localTime = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000);
  
    // Devolver la hora local formateada
    return localTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};