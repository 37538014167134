import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatsState } from './types';

const initialState : ChatsState = {
    loaded : false,
    chats : [],
    hasNewMessages : false
  };

  export const slice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
      setHasNewMessages: (state, action: PayloadAction<boolean>) => {
        state.hasNewMessages = action.payload;
      },
    },
  })
  
  export const { setHasNewMessages } = slice.actions;
  
  export default slice.reducer;



