import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useMember from "../../core/member/useMember";
import Chat from "../../components/Chat/ChatComponent/ChatComponent";
import { useTranslation } from "react-i18next";
import { Main } from "../../components/Main"
import  * as ChatType from '../../core/new-chat/types';
import back from '../../../assets/images/back.svg';
import './ChatPage.scss';
import useSignalR from "../../utils/signalR/useSignalR";
import { useChat } from "../../core/new-chat/useChat";
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from "../../core/reduxProvider";
import { setHasNewMessages } from "../../core/new-chat/store";
const ChatPage: React.FC = () => {
    const { t } = useTranslation();
    const { tenantId, userId } = useParams();
    const { member } = useMember();
    const hub = "notificationhub";
    const { isConnectionLoaded, connection, connectionId } = useSignalR(hub);
    const { state: { chats, loaded }, getHistory, addMessage, updateMessageStatus, sendDirectMessage, connect, initializeChat, addChat, createEmptyChat, initChat } = useChat(tenantId!, connectionId || "");
    const dispatch = useAppDispatch();

    const [activeChat, setActiveChat] = useState<ChatType.Chat | null>(null); //Chat seleccionado
    const [isMobile, setIsMobile] = useState<boolean>(false); //Booleano para indicar si el ancho de la pantalla es tamaño móvil

    const handleSendMessage = async (messageDataToSend : ChatType.MessageDataToSend ) => {
        sendDirectMessage(messageDataToSend.receiverId, messageDataToSend.message, messageDataToSend.senderId);
    };

    const handleSelectChat = async(chat : ChatType.Chat) => {

        if (chat?.chatId == activeChat?.chatId) return;

        setActiveChat(chat);
        
        setActiveChat(prevState => {
            let newChat = {...prevState, ...chat};

            const unreadStatusIds = chat?.messages
            .flatMap(message => 
                message.readStatus
                    .filter(status => status.userId === member?.id && !status.isRead)
                    .map(status => status.id)
            ) || [];

            if (unreadStatusIds.length) {
                updateMessageStatus(unreadStatusIds);
        
            // Mapear todos los mensajes y actualizar los `readStatus` cuando corresponda
            const updatedMessages = chat?.messages.map(message => {
                // Crear una copia del mensaje para no modificar el original directamente
                const updatedMessage = { ...message };
        
                // Actualizar los `readStatus` de este mensaje
                updatedMessage.readStatus = updatedMessage.readStatus.map(status => {
                    if (unreadStatusIds.includes(status.id)) {
                        status.isRead = true;   // Marcar como leído
                        status.readAt = new Date();  // Establecer la fecha de lectura
                    }
                    return status;
                });
        
                return updatedMessage;
                }) || [];

                setActiveChat(prevChat => {
                    if (!prevChat) {
                        return null;
                    }
                
                    const updatedChat = { ...prevChat, messages: updatedMessages };
                
                    return updatedChat;
                });

                

            }

            console.log ("ChatActivo al final del handleSelectChat: ", newChat);
            return newChat
        })

    }
    
   const handleReadMessages = async(chat : ChatType.Chat | null) => {        
        if (chat){
            const unreadStatusIds = chat?.messages
            .flatMap(message => 
                message.readStatus
                    .filter(status => status.userId === member?.id && !status.isRead)
                    .map(status => status.id)
            ) || [];
        
            if (unreadStatusIds.length) {
                updateMessageStatus(unreadStatusIds);
            
                // Mapear todos los mensajes y actualizar los `readStatus` cuando corresponda
                const updatedMessages = chat?.messages.map(message => {
                    // Crear una copia del mensaje para no modificar el original directamente
                    const updatedMessage = { ...message };
            
                    // Actualizar los `readStatus` de este mensaje
                    updatedMessage.readStatus = updatedMessage.readStatus.map(status => {
                        if (unreadStatusIds.includes(status.id)) {
                            status.isRead = true;   // Marcar como leído
                            status.readAt = new Date();  // Establecer la fecha de lectura
                        }
                        return status;
                    });
            
                    return updatedMessage;
                    }) || [];
            
                    setActiveChat(prevChat => {
                        if (!prevChat) {
                            return null;
                        }
                    
                        const updatedChat = { ...prevChat, messages: updatedMessages };
                    
                        return updatedChat;
                    });


            }
        }
    }

    // useEffect(() => {
    //     if (member) {
    //       connect(member);
    //     }
    //   }, [connectionId]);

      useEffect(() => {
        initChat();
      }, [connectionId, isConnectionLoaded]);
      

    useEffect(() => {

        if (tenantId){
            //Obtener el historial de chats
            getHistory();
        }

        const mediaQuery = window.matchMedia("(max-width: 768px)");
        const handleResize = () => setIsMobile(mediaQuery.matches);

        handleResize();

        mediaQuery.addEventListener("change", handleResize);

        return () => mediaQuery.removeEventListener("change", handleResize);
    }, []);

    useEffect(() => {
        if (isConnectionLoaded && connection && connectionId) {
            connection.on("ReceiveDirectMessage", async (message: ChatType.Message, chatId: string) => {
                const existingChat = chats.find(chat =>
                    chat.participants.some(participant => participant.userId === message.sentBy)
                );

                if (!existingChat && member) {
                    let newChat = await createEmptyChat(message.sentBy, member, chatId);
                    if (newChat) {
                        newChat.chatId = chatId;
                         addChat(newChat);
                        addMessage(newChat.chatId, message);
                        
                    }
                } else {
                     addMessage(chatId, message);

                    if (activeChat && existingChat?.chatId === activeChat.chatId) {
                        setActiveChat((prevChat) =>
                            prevChat && prevChat.chatId === activeChat.chatId
                                ? {
                                      ...prevChat,
                                      messages: [...prevChat.messages, message],
                                  }
                                : prevChat
                        );
                    }
                }

                dispatch(setHasNewMessages(true));

            });

            connection.on("SendDirectMessage", async (message: ChatType.Message, chatId: string) => {
      
                // Verificar si ya existe el chat
                const existingChat = chats.find(chat => chat.chatId === chatId);

                if (existingChat) {
                    addMessage(existingChat.chatId, message);
                   
                    if (activeChat && existingChat?.chatId === activeChat.chatId) {
                        setActiveChat((prevChat) =>
                            prevChat && prevChat.chatId === activeChat.chatId
                                ? {
                                      ...prevChat,
                                      messages: [...prevChat.messages, message],
                                  }
                                : prevChat
                        );
                    }
                }
                
                if (!existingChat && member && userId) {
                    
                    let newChat = await createEmptyChat(userId, member, chatId);
                    if (newChat) {
                       
                        addChat(newChat);
                        addMessage(newChat.chatId, message);

                        setActiveChat( {...newChat, messages: [message] }
                        );
                     
                    }
                }

                
                
                
            });

            return () => {
                if (connection) {
                    connection.off("ReceiveDirectMessage");
                    connection.off("SendDirectMessage");
                }
            };

        }
    }, [isConnectionLoaded, connection, connectionId, chats, activeChat]);


    useEffect(() => {
        //En caso de que venga un userId en la URL, activamos el chat
        const initializNewChat = async () => {
            if (tenantId && userId && member) {
                if (loaded && !activeChat) {
                    const chat = await initializeChat(userId, member);

                    if (chat) {
                        setActiveChat(chat);
                        
                    }
                }
            }
        };
    
        initializNewChat();
    }, [userId, member, loaded]);

       
    return (
        <Main ariaLabelledby="chat-title" className="d-flex flex-column chat-page">
            {!isMobile || !activeChat ? (
                <h1 id="chat-title" className="h3 mb-4">
                    {t("chat.title")}
                </h1>
            ) : (
                <button
                    onClick={() => setActiveChat(null)}
                    className="btn btn-back"
                >
                    <img
                        src={back}
                    />
                    {t("chat.title")}
                </button>
            )}

            <div>
                {tenantId && member?.id ? (
                        <Chat 
                            chats={chats}
                            activeChat={activeChat}
                            isMobile={isMobile}
                            onSelectChat={handleSelectChat}
                            onSendMessage={handleSendMessage}
                            onReadMessages={handleReadMessages}
                        />
                ) : (
                    <p>Loading...</p>
                )}
            </div>

        </Main>
      
        
    );
};

export default ChatPage;