import queryString from 'query-string';
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import useSettings from '../../core/settings/useSettings'
import useSnackBars from '../notifications/useNotifications'
import { useCustomRouter } from '../useCustomRouter'
import {
  DeleteParams,
  GetParams,
  PatchParams,
  PostParams,
  PutParams,
  UseFetchResult,
} from './types'

const useFetch = (baseUrl?: string): UseFetchResult => {
  const navigate = useNavigate()
  const notify = useSnackBars()
  const { t } = useTranslation()
  const { settings } = useSettings()
  const { basePath } = useCustomRouter()
  const { tenantId } = useParams()

  const token = localStorage.getItem(
    `ifema_communities_web_access_token${tenantId}`
  )

  if (!baseUrl) {
    baseUrl = settings?.bff?.url || ''
  }

  const getHeaders = (
    isPublic?: boolean,
    isMultipart?: boolean
  ): HeadersInit => {
    const requestHeaders: HeadersInit = new Headers()
    if (!isMultipart) {
      requestHeaders.set('Content-Type', 'application/json')
      requestHeaders.set('Accept', 'application/json')
    }
    if (!isPublic) {
      requestHeaders.set('Authorization', `Bearer ${token}`)
    }
    return requestHeaders
  }

  const errorHandler = async (response: Response) => {
    if (response.status === 403) {
      navigate(`${basePath}/access-denied`)
    }
    if (response.status === 500) {
      notify.error(t('common.unknownError'))
    }
    const error = await response.clone().json()
    throw {
      status: response.status,
      body: error,
    }
  }

  const responseHandler = async function responseHandler<T>(
    response: Response
  ): Promise<T | undefined> {
    if (!response.ok) {
      await errorHandler(response)
    }
    if (response.status === 204) return
    if (response.json) {
      const result: T = await response.json()
      return result
    }
    return
  }

  const get = async function get<K, T>({
    endpoint,
    params,
    isPublic,
  }: GetParams<K>): Promise<T | undefined> {
    const url = `${baseUrl}/${endpoint}${params ? `?${queryString.stringify(params)}` : ''}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaders(isPublic),
    })
    const parsedResponse = await responseHandler<T>(response)
    return parsedResponse
  }

  const post = async function post<T>({
    endpoint,
    body,
    isPublic,
    isMultipart,
  }: PostParams): Promise<T | undefined> {
    const url = `${baseUrl}/${endpoint}`
    const response = await fetch(url, {
      body: (isMultipart ? body : JSON.stringify(body)) as BodyInit,
      method: 'POST',
      headers: getHeaders(isPublic, isMultipart),
    })
    const parsedResponse = await responseHandler<T>(response)
    return parsedResponse
  }

  const put = async function put<T>({
    endpoint,
    id,
    body,
    isPublic,
    isMultipart,
  }: PutParams): Promise<T | undefined> {
    let url = `${baseUrl}/${endpoint}`
    if (typeof id !== 'undefined') url += `/${id}`
    const response = await fetch(url, {
      body: (isMultipart ? body : JSON.stringify(body)) as BodyInit,
      method: 'PUT',
      headers: getHeaders(isPublic, isMultipart),
    })
    const parsedResponse = await responseHandler<T>(response)
    return parsedResponse
  }

  const patch = async function patch<T>({
    endpoint,
    body,
    isPublic,
    isMultipart,
  }: PatchParams): Promise<T | undefined> {
    const url = `${baseUrl}/${endpoint}`
    const response = await fetch(url, {
      body: (isMultipart ? body : JSON.stringify(body)) as BodyInit,
      method: 'PATCH',
      headers: getHeaders(isPublic, isMultipart),
    })
    const parsedResponse = await responseHandler<T>(response)
    return parsedResponse
  }

  const del = async function del<T>({
    endpoint,
    id,
    isPublic,
    params,
  }: DeleteParams): Promise<T | undefined> {
    let url = `${baseUrl}/${endpoint}`
    if (id) url += `/${id}`
    const response = await fetch(url, {
      method: 'DELETE',
      headers: getHeaders(isPublic),
      body: JSON.stringify(params),
    })
    const parsedResponse = await responseHandler<T>(response)
    return parsedResponse
  }

  return {
    get,
    post,
    put,
    patch,
    del,
  }
}

export default useFetch
