
import { Avatar } from '@liveconnect/components';
import useMember from '../../../core/member/useMember';
import { ChatParticipant, Message } from '../../../core/new-chat/types';
import './MessagesList.scss';
import { FormatOptions, useDateFormat } from '@liveconnect/communities-ui';
import { formatTime } from '../../../core/new-chat/chatUtils';



type MessagesListProps = {
    messages: Message[];
    otherParticipant: ChatParticipant | null;
};

const formatRelativeDate = (date: Date, formatDate: (date: Date, options? : FormatOptions) => string, options? : FormatOptions): string => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    //TODO Traducciones

    if (date.toDateString() === today.toDateString()) return "Hoy";
    if (date.toDateString() === yesterday.toDateString()) return "Ayer";

    return formatDate(date, options);
};


const MessagesList = ({ messages, otherParticipant }: MessagesListProps) => {
    const { member } = useMember();
    const { formatDate } = useDateFormat();
    const myId = member?.id;

    // Convertir sentAt de string a Date si es necesario
    const formattedMessages = messages.map((message) => {
        const sentAt = typeof message.sentAt === 'string' ? new Date(message.sentAt) : message.sentAt;
        return { ...message, sentAt };
    });

    const sortedMessages = formattedMessages.sort((a, b) => a.sentAt.getTime() - b.sentAt.getTime());

    // Filtrar mensajes no leídos
    const unreadMessages = sortedMessages.filter(message =>
        message.readStatus.some(status => status.userId === myId && !status.isRead)
    );

    const firstUnreadIndex = unreadMessages.length > 0 ? sortedMessages.indexOf(unreadMessages[0]) : -1;

    const groupedMessages = sortedMessages.reduce((acc: Record<string, Message[]>, message, index) => {
        const date = formatRelativeDate(message.sentAt, formatDate, { format: { month: 'long', day: 'numeric', weekday: 'long' }, localTZ: false });

        if (!acc[date]) acc[date] = [];

        // Insert "Nuevos mensajes" marker if it's the first unread message
        if (index === firstUnreadIndex) {
            acc[date].push({ ...message, isUnreadMarker: true } as Message);
        }

        acc[date].push(message);
        
        return acc;
        
    }, {});

    

    return (
        <div className="messages-list">
            {Object.entries(groupedMessages).map(([date, dayMessages]) => (
                <div key={date} className="messages-list__date-group">
                    <div className="messages-list__date-group__date">{date}</div>
                    {dayMessages.map((message, index) => {
                        if (message.isUnreadMarker) {
                            return (
                                <div key={`unread-${index}`} className="messages-list__unread-marker">
                                    Nuevos mensajes
                                </div>
                            );
                        }

                        const isMine = message.sentBy === myId;
                        const isNextByOther = index < dayMessages.length - 1 && dayMessages[index + 1].sentBy !== myId;

                        const showAvatar =
                            !isMine &&
                            (index === 0 ||
                            dayMessages[index - 1].sentBy !== message.sentBy ||
                            dayMessages[index - 1].isUnreadMarker);


                        const showTimestamp =
                            index === 0 ||
                            isMine !== (dayMessages[index - 1].sentBy === myId) ||
                            formatTime(message.sentAt) !== formatTime(dayMessages[index - 1].sentAt);

                        const receivedClass = !isMine && !showAvatar ? "no-avatar" : "";

                        return (
                            <div
                                key={message.messageId}
                                className={`message-item ${
                                    isMine ? "message-item--mine" : `message-item--received ${receivedClass}`
                                } ${isMine && isNextByOther ? 'message-item--margin-bottom' : ''}`}
                            >
                                {showTimestamp && (
                                    <div
                                        className={`messages-list__hour-group__hour ${
                                            isMine
                                                ? "messages-list__hour-group__hour--right"
                                                : "messages-list__hour-group__hour--left"
                                        }`}
                                    >
                                        {formatTime(message.sentAt)}
                                    </div>
                                )}
                                <div className="message-item__content">
                                    {!isMine && showAvatar && otherParticipant && (
                                        <Avatar name={otherParticipant.name} surname={otherParticipant.surname} imageUrl={otherParticipant.imageUrl} size="sm" />
                                    )}
                                    <div className="message-item__bubble">{message.content}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};



export default MessagesList;
