import { useCallback, useEffect, useState } from "react";
import useFetch from "../../utils/fetch/useFetch";
import { Member } from "../member/types";
import { Chat, ChatParticipant, ChatsState, Message } from "./types";
import { mockChats } from "../../pages/NewChat/mockChats";
import { returnHypphen } from "../../utils/showHyppen/showHyppen";
import { useAppDispatch, useAppSelector } from "../reduxProvider";
import useSignalR from "../../utils/signalR/useSignalR";
import useMember from "../member/useMember";



const initialState : ChatsState = {
  loaded : false,
  chats : [],
  hasNewMessages : false
};


export const useChat = (tenantId : string, connectionId : string) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [state, setState] = useState<ChatsState>(initialState);
  const { member } = useMember();
  const hub = "notificationhub";
    const { isConnectionLoaded, connection } = useSignalR(hub);

  // Base URL con versión fija
  const endpoint = 'chat'
  const { get, post } = useFetch();

  const dispatch = useAppDispatch();

  const {hasNewMessages } = useAppSelector(
    (state) => state.chats
  )

  const sendMessageToChat = async (user: string, message: string): Promise<void> => {
    try {
      const body = {
        username : user,
        message : message
      };
      await post({
        endpoint: `${endpoint}/send`,
        body : body
      });

    } catch (error) {
      console.error("Error al enviar el mensaje", error);
    }
  };

  const joinGroup = async (user : Member, groupId : string) => {
    const body = {
      tenantId : tenantId,
      connectionId : connectionId,
      username: (user.name || '') + ' ' + (user.surname || ''),
      groupId : groupId
    }

    try {

      await post({
        endpoint: `${endpoint}/joinGroup`,
        body : body
      });

    } catch (error) {
      console.error("Error al entrar al grupo", error);
    }

  }

  const leaveGroup = async (user : Member, groupId : string) => {
    const body = {
      tenantId : tenantId,
      connectionId : connectionId,
      username: (user.name || '') + ' ' + (user.surname || ''),
      groupId : groupId
    }

    try {

      await post({
        endpoint: `${endpoint}/leaveGroup`,
        body : body
      });

    } catch (error) {
      console.error("Error al entrar al grupo", error);
    }

  }

  const sendMessageToGroup = async (user: string, message: string, groupId : string): Promise<void> => {
    try {
      const body = {
        username : user,
        message : message,
        tenantId : tenantId,
        groupId : groupId
      };
      await post({
        endpoint: `${endpoint}/sendToGroup`,
        body : body
      });

    } catch (error) {
      console.error("Error al enviar el mensaje", error);
    }
  };

  const connect = async(user : Member) => {
    if (!connectionId) return;

    const body = {
      tenantId : tenantId,
      connectionId : connectionId,
      userId : user.id
    }

    try {
      await post({
        endpoint: `${endpoint}/handleConnectionStarted`,
        body : body
      });
    } catch (error) {
      console.error("Error al enviar el mensaje", error);
    }

  }

  const sendDirectMessage = async (receiverUserId: string, message: string, senderId : string): Promise<void> => {
    try {
      const body = {
        receiverUserId,
        senderUserId: senderId,
        message,
      };
  
      await post({
        endpoint: `${endpoint}/sendDirectMessage`,
        body,
      });
    } catch (error) {
      console.error("Error al enviar el mensaje directo", error);
    }
  };

  const updateMessageStatus = async (messageReadStatusIds : string[]) => {
    const body = {
      messageReadStatusIds
    };

    try {
      await post({
        endpoint: `${endpoint}/updateMessageStatus`,
        body,
      });
    } catch (error) {
      console.error("Error al enviar el mensaje directo", error);
    }

  };

  const getHistory = useCallback(
    async() => {

      setState(prevState => ({
        ...prevState,
        loaded: false,
      }));

      try {
        const chats : Chat[] = await get({endpoint: `${endpoint}/chat-history`,}) ?? [];
        // const chats = mockChats;
        setState(prevState => ({
          ...prevState,
          loaded: true,
          chats
        }));
      } catch (error) {
        console.error("Error al obtener el historial de chats:", error);
      }
    },
     []
  );

  const getUserInfo = useCallback(
    async(userId : string) => {
      const userInfo : ChatParticipant | null = await get({endpoint: `${endpoint}/get-user-information/${userId}`}) ?? null;

      return userInfo;
    }, 
    []
  );

  const sortChatsByLastMessage = (chats: Chat[]): Chat[] => {
    return chats.sort((a, b) => {
        const lastMessageA = a.messages[a.messages.length - 1];
        const lastMessageB = b.messages[b.messages.length - 1];
        return new Date(lastMessageB.sentAt).getTime() - new Date(lastMessageA.sentAt).getTime();
    });
};

const addMessage = (chatId: string, newMessage: Message) => {
  

  setState(prevState => {
    
      // Actualiza el chat con el nuevo mensaje
      const updatedChats = prevState.chats.map(chat =>
          chat.chatId === chatId
              ? { ...chat, messages: [...chat.messages, newMessage] }
              : chat
      );

      const sortedChats = sortChatsByLastMessage(updatedChats);
      
      const newState = {
          ...prevState,
          chats: sortedChats,
      };

      return newState;
  });
};

  const initializeChat = async (userId: string, member: Member | null): Promise<Chat | null> => {
    //Comprobar si el chat ya existe
    const matchedChat = state.chats.find(
        (chat) =>
            chat.participants.length === 2 &&
            chat.participants.some(participant => participant.userId === userId)
    );

    if (matchedChat) {
        return matchedChat;
    } else {
      //Si el chat no existe, obtenemos los datos del otro participante para crear un chat "temporal"
        const userInfo = await getUserInfo(userId);

        if (userInfo && member) {
          const newChat = await createEmptyChat(userId, member);

            return newChat;
        }
    }

    return null;
}

const addChat = (newChat: Chat) => {
  setState(prevState => {
      const newState = {
          ...prevState,
          chats: [...prevState.chats, newChat],
      };
      return newState;
  });
};

const createEmptyChat = async (userId: string, member: Member, chatId? : string): Promise<Chat | null> => {
  const userInfo = await getUserInfo(userId);
  if (!userInfo || !member) return null;

  const memberJobTitle =
      member.positions?.length > 0 &&
      `${member.positions[0].jobTitle} ${returnHypphen(
          member.positions[0].jobTitle,
          member.positions[0].company
      )} ${member.positions[0].company}`;

      const userJobTitle = userInfo.positions && member.positions.length > 0
      ? `${userInfo.positions[0].jobTitle} ${returnHypphen(
        userInfo.positions[0].jobTitle,
        userInfo.positions[0].company
      )} ${userInfo.positions[0].company}`
      : undefined;

  return {
      chatId: chatId || "",
      createdAt: new Date(),
      createdBy: member.id || "",
      participants: [
          {
              userId: member.id || "",
              name: member.name,
              surname: member.surname,
              jobTitle: memberJobTitle || "",
              imageUrl: member.imageUrl || "",
              joinedAt: new Date().toISOString(),
          },
          {
              userId: userInfo.userId,
              name: userInfo.name,
              surname: userInfo.surname,
              jobTitle: userInfo.jobTitle || userJobTitle || "",
              imageUrl: userInfo.imageUrl,
          },
      ],
      messages: [],
  };
};

const initChat = useCallback(async () => {
  if (isConnectionLoaded) {
    console.log("isConnectionLoaded true");
    return;
  }

  console.log("isConnectionLoaded false");

  if (member){
    connect(member);

    const hasUnreadMessages = await get({endpoint: `${endpoint}/getUnreadMessages`})
    console.log("hasUnreadMessages: ", hasUnreadMessages);
  }
  
 
}, [isConnectionLoaded]);

  return {
    isConnected,
    sendMessageToChat,
    joinGroup,
    leaveGroup,
    sendMessageToGroup,
    sendDirectMessage, 
    connect,
    getHistory,
    state,
    addMessage,
    getUserInfo,
    initializeChat,
    addChat,
    createEmptyChat,
    updateMessageStatus,
    initChat
  };
};
