import React from 'react';
import './ConversationItem.scss';

import deleteIcon from '../../../../assets/images/delete.svg';
import { Chat } from '../../../core/new-chat/types';
import useMember from '../../../core/member/useMember';
import { Avatar } from '@liveconnect/components';
import useUi from '../../../core/ui/useUi';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '@liveconnect/communities-ui';
import { formatTime } from '../../../core/new-chat/chatUtils';

interface ConversationItemProps {
  chat: Chat;
  isActive: boolean;
  onClick?: () => void;
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  chat,
  isActive,
  onClick
}) => {
  const { t } = useTranslation();
  const { showConfirmation } = useUi();
  const { formatDate } = useDateFormat();

  const { name, messages } = chat;


  const sortedMessages = [...messages].sort((a, b) => {
    const dateA = new Date(a.sentAt).getTime();
    const dateB = new Date(b.sentAt).getTime();
    return dateA - dateB;
  });

  

  let otherName = '';
  let lastMessage;
if (sortedMessages.length === 1) {
    lastMessage = sortedMessages[0]; // Solo un elemento, lo obtenemos directamente.
} else if (sortedMessages.length > 1) {
    lastMessage = sortedMessages[sortedMessages.length - 1]; // Más de un elemento, obtenemos el último.
} else {
    lastMessage = null;
}


  const lastMessageContent = lastMessage?.content || '';

    const formatLastMessageTime = (date: Date | string): string => {
      
      // Si la fecha es un string, conviértelo a Date
      const dateObj = typeof date === "string" ? new Date(date) : date;
  
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
  
      // Si la fecha es hoy, retorna la hora en formato hh:mm
      if (dateObj.toDateString() === today.toDateString()) {
          return formatTime(dateObj);
      }
  
      // Si la fecha es ayer, retorna "Ayer"
      if (dateObj.toDateString() === yesterday.toDateString()) {
          return "Ayer";
      }
  
      // Si la fecha es anterior retorna "dia/mes"
      return  formatDate(dateObj, { format: { month: 'short', day: 'numeric'}, localTZ: false });
    };

    const { member } = useMember();
    const myId = member?.id;

    let participantName = "";
    let participantSurname = "";
    let participantImageUrl = "";

    //TODO Comprobar también el tipo de chat y no solo el nombre
    if (chat.participants.length === 2) {
      const otherParticipant = chat.participants.find(participant => participant.userId !== myId);
      
      if (otherParticipant) {
        const { name, surname, imageUrl } = otherParticipant;
        participantName = name;
        participantSurname = surname;
        participantImageUrl = imageUrl || "";
        otherName = `${name} ${surname}`;
      }
    }


    const unreadMessages = sortedMessages.filter(message =>
      message.readStatus.some(status => status.userId === myId && !status.isRead)
    );

    const handleConfirmDelete = (id: string) => {
      showConfirmation({
        title: t('chat.delete.title'),
        subtitle: t('chat.delete.subtitle'),
        confirmText: t('common.delete'),
        iconName: 'report_problem',
        onConfirm: () => handleDelete(id),
      })
    };

    const handleDelete = (id : string) => {
      //TODO Eliminar chat
    }
    

  return (
    <div className={`conversation-item ${isActive ? 'conversation-item--active' : ''}`} onClick={onClick}>
      <Avatar name={participantName} surname={participantSurname} imageUrl={participantImageUrl} size="md" />
      <div className='conversation-item__details'>
        <div className='conversation-item__details__texts'>
        <p className="conversation-item__details__name" title={name || otherName}>{name || otherName}</p>
          <p 
            className={`conversation-item__details__last-message ${unreadMessages && unreadMessages.length > 0 ? 'conversation-item__details__last-message--unread' : ''}`} 
           title={lastMessageContent}>
            {lastMessageContent}
            </p>
        </div>
        <div className='conversation-item__details__actions'>
        {lastMessage && (
          <p className='conversation-item__details__time'>
            {formatLastMessageTime(lastMessage.sentAt)}
          </p>
        )}
          <button
            className="conversation-item__delete-button"
            aria-label="Eliminar conversación"
            onClick={() => handleConfirmDelete(chat.chatId)}
          >
           <img
                src={deleteIcon}
              />
          </button>
        </div>
      </div>
      {/* TODO: unreadMessages */}
      {unreadMessages && unreadMessages.length > 0 && (
          <div className="conversation-item__unread-messages"></div>
        )}
    </div>
  );
};

export default ConversationItem;
