import React from 'react';
import './ConversationsList.scss';
import ConversationItem from '../ConversationItem/ConversationItem';
import { Chat } from '../../../core/new-chat/types';
import { SearchInput } from '@liveconnect/communities-ui';

interface ConversationsListProps {
  chats: Chat[];
  activeChat: Chat | null;
  onSelectChat: (chat: Chat) => void;
}

const ConversationsList: React.FC<ConversationsListProps> = ({ chats, activeChat, onSelectChat }) => {
  return (
    <div className="conversations-list">
      {/* Descomentar en fase 2 */}
      {/* <div className='conversations-list__actions'>
        <SearchInput
          onSearch={() => {}}
          defaultValue=''
          placeholder='Buscar en el chat'
        />
        <button className='btn'>Nuevo Chat</button>
      </div> */}
      <div className='conversations-list__container'>
      {chats.map((chat) => {
        return (
          <ConversationItem
            key={chat.chatId}
            chat={chat}
            isActive={activeChat?.chatId === chat.chatId}
            onClick={() => onSelectChat(chat)} 
          />
        );
      })}
      </div>
      
    </div>
  );
};

export default ConversationsList;
