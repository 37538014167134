import { useCallback } from 'react'
import { CometChat } from '@cometchat-pro/chat'

import { UseChatResult, CometchatToken } from './types'
import useFetch from '../../utils/fetch/useFetch'
import { setHasErrors, setHasNewMessages, setIsLoaded } from './store'
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import useTenantConfig from '../tenantConfig/useTenantConfig'

const useChat = (): UseChatResult => {
  const dispatch = useAppDispatch()
  const { isLoaded, hasNewMessages, hasErrors } = useAppSelector(
    (state) => state.chat
  )
  const { get } = useFetch()
  const { tenantConfig } = useTenantConfig()

  const appId = tenantConfig?.chatConfiguration?.appId || ''
  const region = tenantConfig?.chatConfiguration?.region || ''

  const endpoint = 'cometchat'

  const loginChat = useCallback(async () => {
    console.log("loginChat");
    const response: CometchatToken | undefined = await get({
      endpoint: `${endpoint}/token`,
    })
    if (response) {
      await CometChat.login(response.token)
      subscribeToMessages(response.token)
    }
  }, [])

  const subscribeToMessages = (token: string) => {
    CometChat.addMessageListener(
      token,
      new CometChat.MessageListener({
        onTextMessageReceived: () => {
          dispatch(setHasNewMessages(true))
        },
        onMediaMessageReceived: () => {
          dispatch(setHasNewMessages(true))
        },
        onCustomMessageReceived: () => {
          dispatch(setHasNewMessages(true))
        },
      })
    )
  }

  const initChat = useCallback(async () => {
    console.log("initChat");
    console.log("CometChat.isInitialized(): ", CometChat.isInitialized());
    if (CometChat.isInitialized() && !hasErrors) return
    try {
      const appSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(region)
        .build()
      await CometChat.init(appId, appSetting)
      await loginChat()
      const response = await CometChat.getUnreadMessageCount()
      if (Object.keys(response.users).length) {
        dispatch(setHasNewMessages(true))
      }
      dispatch(setHasErrors(false))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('CHAT CONECTION ERROR')
      dispatch(setHasErrors(true))
    } finally {
      dispatch(setIsLoaded(true))
    }
  }, [appId, region, loginChat])

  return {
    isLoaded,
    hasNewMessages,
    hasErrors,
    initChat,
    setHasNewMessages: (hasMessages) =>
      dispatch(setHasNewMessages(hasMessages)),
  }
}

export default useChat
