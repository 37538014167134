import React, { useEffect, useState } from "react";
import ConversationsList from "../ConversationsList/ConversationsList";
import ChatWindow from "../ChatWindow/ChatWindow";
import './ChatComponent.scss';
import  * as ChatType from '../../../core/new-chat/types';

interface ChatComponentProps {
  chats : ChatType.Chat[];
  activeChat : ChatType.Chat | null;
  onSelectChat : (chat: ChatType.Chat) => void;
  isMobile : boolean;
  onSendMessage: (messageDataToSend : ChatType.MessageDataToSend) => void;
  onReadMessages: (chat : ChatType.Chat | null) => void;
}

const Chat: React.FC<ChatComponentProps> = ({chats, activeChat, onSelectChat, isMobile, onSendMessage, onReadMessages}) => {
  return (

    <div className="chat-container">
    {/* Mostrar lista de conversaciones en móviles y pantallas grandes */}
    {!isMobile || !activeChat ? (
        <ConversationsList chats={chats} activeChat={activeChat} onSelectChat={onSelectChat} />
    ) : null}

    {/* Mostrar la ventana de chat solo cuando se seleccione una conversación */}
    {!isMobile || activeChat ? (
        <ChatWindow chat={activeChat} onSendMessage={onSendMessage} onReadMessages={onReadMessages} />
    ) : null}
</div>

  );
};

export default Chat;
