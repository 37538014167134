import React, { useEffect, useRef, useState } from 'react';
import './ChatWindow.scss';

import send from '../../../../assets/images/send.svg';
import MessagesList from '../MessagesList/MessagesList';
import { Chat, ChatParticipant, MessageDataToSend } from '../../../core/new-chat/types';
import { Avatar } from '@liveconnect/components';
import useMember from '../../../core/member/useMember';
import chevronDownIcon from '../../../../assets/images/chevron-down.svg';

interface ChatWindowProps {
  chat: Chat | null;
  onSendMessage: (messageData: MessageDataToSend) => void;
  onReadMessages: (chat : Chat | null) => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ chat, onSendMessage, onReadMessages }) => {
  const { member } = useMember();
  const myId = member?.id;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const chatIdRef = useRef<string | undefined>();
  const [showEndButton, setShowEndButton] = useState(false);

  const unreadMessages = chat?.messages.filter(message =>
    message.readStatus.some(status => status.userId === myId && !status.isRead)
  );

  
  useEffect(() => {
    console.log("USEEFFECT CHAT EN CHATWINDOW:", chat);
    if (chat) {
      chatIdRef.current = chat.chatId;
    }
  }, [chat]);

  // State para almacenar al otro participante
  const [otherParticipant, setOtherParticipant] = useState<ChatParticipant | null>(null);

  // Actualiza el otro participante cuando el chat cambia
  useEffect(() => {
    if (chat && chat.participants.length === 2) {
      const participant = chat.participants.find((p) => p.userId !== myId) || null;
      setOtherParticipant(participant);
      if (participant) {
        const newChatName = `${participant.name} ${participant.surname}`;
        chat.name = newChatName;
      }
    } else {
      setOtherParticipant(null);
    }
  }, [chat, myId]);

  //Desplazar el scroll al último mensaje
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [chatIdRef.current]);

  useEffect(() => {
    let lastMessage;
    if (chat?.messages) {
      if (chat?.messages.length === 1) {
          lastMessage = chat?.messages[0]; // Solo un elemento, lo obtenemos directamente.
      } else if (chat?.messages.length > 1) {
          lastMessage = chat?.messages[chat?.messages.length - 1]; // Más de un elemento, obtenemos el último.
      } else {
          lastMessage = null;
      }
    }

    let lastMessageIsMine = lastMessage?.sentBy === myId;

    
    if (messagesContainerRef.current && lastMessageIsMine) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [chat?.messages]);

  useEffect(() => {
    console.log("useEffect handleScroll");
    const handleScroll = () => {
      if (messagesContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
        const atBottom = scrollTop + clientHeight >= scrollHeight - 10; // Ajuste pequeño para evitar margen de error
        setShowEndButton(!atBottom);

        // Llamar a onReadMessages cuando el scroll esté al fondo
        if (atBottom) {
          console.log("IF ATBOTTOM CHAT: ", chat);
          onReadMessages(chat);
        }
      }
    };
  
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
  
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messagesContainerRef.current, chat]);
  

  const handleSendMessage = () => {
    if (inputRef.current?.value.trim() && otherParticipant && member) {
      let messageData : MessageDataToSend = {
        receiverId : otherParticipant?.userId,
        message : inputRef.current?.value,
        senderId : member.id || ""
      };
      onSendMessage(messageData);
      onReadMessages(chat);
      inputRef.current.value = ''; // Clear the input field
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleScrollBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      onReadMessages(chat);
    }
  };

  if (!chat) {
    return <div className="chat-window" />;
  }

  return (
    <div className="chat-window">
      <div className="chat-window__header">
  {otherParticipant && (
    <Avatar
      name={otherParticipant.name || ''}
      surname={otherParticipant.surname || ''}
      imageUrl={otherParticipant.imageUrl || ''}
      size="lg"
    />
  )}
  <div className='chat-window__header__data'>
    {chat.name || otherParticipant ? (
      <>
        <div className="chat-window__header__data__title">
          {chat.name || `${otherParticipant?.name} ${otherParticipant?.surname}`}
        </div>
        <div className="chat-window__header__data__job">
          {otherParticipant?.jobTitle}
        </div>
      </>
    ) : null}
  </div>
</div>
      <div className="chat-window__messages-container" ref={messagesContainerRef}>
        <MessagesList messages={chat.messages} otherParticipant={otherParticipant} />
      </div>
      {showEndButton && (
        <div className="chat-window__end-button-container">
          <button className="chat-window__end-button" onClick={handleScrollBottom}>
            <img src={chevronDownIcon} alt="Desplazar al final" />
          </button>
          {unreadMessages && unreadMessages.length > 0 && (
            <span className="chat-window__end-button__badge"></span>
          )}
      </div>
      )}
      <div className="chat-window__input-container">
        <input
          type="text"
          placeholder="Escribe un mensaje"
          ref={inputRef}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSendMessage}>
          <img src={send} alt="Enviar mensaje" />
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
